import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/components/index.vue'),
    },
    {
        path: '/lookup-tree',
        name: 'lookup-tree',
        component: () => import('@/components/tree.vue'),
        children: [
            {
                path: 'thematic',
                name: 'thematic',
                component: () => import('@/views/thematic.vue'),
            },
            {
                path: '/thematic/:id',
                name: 'thematic-detail',
                component: () => import('@/views/thematic-detail.vue'),
            },
            {
                path: ':category',
                name: 'tree-category',
                component: () => import('@/views/artifact.vue'),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
